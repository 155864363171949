<template>
    <div class="container-fluid">
        <div class="row mt-4">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col ml-n2">
                                <h4 class="mb-1">
                                    <a>{{$t('deposits.show.expense')}} <strong># {{item.id}}</strong></a>
                                </h4>
                            </div>
                            <div class="col-auto">
                                <router-link :to="path+'/'+item.id + '/edit'" class="btn btn-sm btn-primary d-none d-md-inline-block">
                                    <i class="fas fa-edit"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-header-title">
                            <i class="fal fa-file-signature"></i>
                            {{$t('deposits.show.expenseDetails')}}
                        </h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <table class="table table-sm table-nowrap customed-table-small">
                                    <tbody>
                                        <tr>
                                            <th scope="row">{{$t('deposits.show.TheOwnerOfTheCheck')}} </th>
                                            <td>{{item.owner_name != null ? item.owner_name : '--'}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{$t('deposits.show.AdapterFrom')}} </th>
                                            <td>{{item.converter_name != null ? item.converter_name : '--'}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{$t('deposits.show.ConverterTo')}} </th>
                                            <td>{{item.converted_to != null ? item.converted_to : '--'}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{$t('deposits.show.bankName')}} </th>
                                            <td>{{item.bank_name != null ? item.bank_name : '--'}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{$t('deposits.show.accountNumber')}} </th>
                                            <td>{{item.account_number != null ? item.account_number : '--'}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{$t('deposits.show.PaymentNumber')}}</th>
                                            <td>{{item.identification_number != null ? item.identification_number : '--'}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{$t('deposits.show.PaymentDate')}}</th>
                                            <td>{{$dateFormat(item.date)}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{$t('deposits.show.AmountOfCheque')}}</th>
                                            <td>{{item.cost != null ? item.cost + ' ' + $option.currency : '--'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div col-12>
                            <div class="w-100" v-if="item.description != null">
                                <hr class="my-3">
                                <h4 class="text-uppercase">{{$t('deposits.show.description')}}</h4>
                                <p class="text-muted mb-0">{{item.description}}</p>
                            </div>
                            <div class="w-100" v-if="item.data1 != null">
                                <hr class="my-3">
                                <h4 class="text-uppercase">{{$t('OtherData1OtherData1')}}</h4>
                                <p class="text-muted mb-0">{{item.data1}}</p>
                            </div>
                            <div class="w-100" v-if="item.data2 != null">
                                <hr class="my-3">
                                <h4 class="text-uppercase">{{$t('deposits.show.OtherData2')}}</h4>
                                <p class="text-muted mb-0">{{item.data2}}</p>
                            </div>
                            <div class="w-100" v-if="item.notes != null">
                                <hr class="my-3">
                                <h4 class="text-uppercase">{{$t('deposits.show.notes')}}</h4>
                                <p class="text-muted mb-0">{{item.notes}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <print v-if="printInvoice"></print>
    </div>
</template>
<script>
    import axios from 'axios';

    import print   from './components/print.vue';
    export default {
        data() {
            return {
                path: '/deposits',
                item: {},

                printInvoice: false,
            }
        },
        mounted() {
            this.getitem();
        },
        methods: {
            getitem() {
                axios.get(this.$linkGnirator(this.path + '/' + this.$route.params.id))
                    .then(response => {
                        this.item = response.data;
                    });
            },
            print (id) {
                this.$option.item_numberInvoice = id;
            }
        },
        components: {
            print
        }
    };

</script>
<style>
</style>
