<template>
    <div class="print-model">
        <div id="printMe" ref="printMe" v-if="!loading">
            <div class="col text-center">
                <div class="row">
                    <img :src="$linkGnirator('/companiesUploads/' + subdomain + '/logos/' + $option.image)" class="img-fluid mb-4" style="max-width: 4.5rem;" v-if="$option.image != null">
                    <h2 class="mb-2">
                        {{$t('components.deposits.Endorsement')}}
                    </h2>
                </div>
            </div>
            <div class="row">
                <table class="table table-sm table-nowrap customed-table-small col-12">
                    <tbody>
                        <tr>
                            <th scope="row">{{$t('components.deposits.TheRecipientIsName')}}</th>
                            <td>{{item.name != null ? item.name : '--'}}</td>
                        </tr>
                        <tr>
                            <th scope="row">{{$t('components.deposits.CardNumber')}}</th>
                            <td>{{item.identification_number != null ? item.identification_number : '--'}}</td>
                        </tr>
                        <tr>
                            <th scope="row">{{$t('components.deposits.date')}}</th>
                            <td>{{item.date}}</td>
                        </tr>
                    </tbody>
                </table>

                <div class="col-12 customed-table-small">
                    <div class="w-100" v-if="item.description">
                        <hr class="my-3">
                        <h4 class="text-uppercase">{{$t('components.deposits.description')}}</h4>
                        <p class="text-muted mb-0">{{item.description}}</p>
                    </div>
                    <div class="w-100" v-if="item.data1">
                        <hr class="my-3">
                        <h4 class="text-uppercase">{{$t('components.deposits.OtherData1')}}</h4>
                        <p class="text-muted mb-0">{{item.data1}}</p>
                    </div>
                    <div class="w-100" v-if="item.data2">
                        <hr class="my-3">
                        <h4 class="text-uppercase">{{$t('components.deposits.OtherData2')}}</h4>
                        <p class="text-muted mb-0">{{item.data2}}</p>
                    </div>
                    <div class="w-100" v-if="item.notes">
                        <hr class="my-3">
                        <h4 class="text-uppercase">{{$t('components.deposits.notes')}}</h4>
                        <p class="text-muted mb-0">{{item.notes}}</p>
                    </div>
                </div>
                <div class="col-12" style="text-align:left; padding-left: 200px;">{{$t('components.deposits.TheRecipientIsSignature')}} :</div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios';

    export default {
        data() {
            return {
                path: '/endorsements',
                subdomain: localStorage.getItem('subdomain'),
                item: {},
                loading: true
            }
        },
        methods: {
            getitem() {
                axios.get(this.$linkGnirator(this.path + '/' + this.$parent.printInvoice))
                    .then(response => {
                        this.item = response.data;
                        this.loading = false;
                        var myel = this;
                        setTimeout(function() {
                            var text = document.getElementById("printMe").innerHTML;
                            document.getElementById("printMe").remove();
                            var myframe = document.getElementById('printIframe');
                            myframe.contentDocument.body.innerHTML = text;
                            setTimeout(function() {
                                myframe.focus();
                                myframe.contentWindow.print();
                                myel.colse();
                            }, 200);
                        }, 20);
                    });
            },
            colse() {
                this.$parent.printInvoice = false;
            }
        },
        mounted() {
            this.getitem();
        }
    }

</script>
